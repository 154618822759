import { useHttpClient } from "@my/api"
import { Button, CheckboxWithLabel, ShadowlessDialogContent } from "@my/ui"
import { useUserStore } from "app/global-state/userState"
import { mixpanel, trackButtonClicked } from "app/telemetry"
import { useUtm } from "app/utm/useUtm"
import { useCallback, useEffect, useState, memo } from "react"
import { Dialog, H2, Text, YStack, styled, useMedia } from "tamagui"
import { formatUtmForMixpanel } from "app/utm/formatUtmForMixpanel"
import { Platform, Linking, Dimensions } from "react-native"
import { useKeyboardDismiss } from "app/utils/screenUtils"
import { CLOSEDLOOP_URL_CONFIG } from "@my/api/src/constants"

/**
 * Represents the Terms of Service data structure.
 * This interface defines the shape of the Terms of Service object
 * returned from the API and used throughout the Terms and Conditions flow.
 */
interface TermsOfService {
  /** Unique identifier for the Terms of Service version */
  id: string
  /** Version number/string of the Terms of Service document */
  version: string
}

/**
 * Props interface for the TermsDialog component.
 * Defines the required properties for rendering and managing the Terms and Conditions dialog.
 */
interface TermsDialogProps {
  /** Whether the user has already consented to the terms */
  consented: boolean
  /** The current Terms of Service data */
  termsOfService: TermsOfService
  /** Whether the user has accepted the Terms of Service */
  acceptTos: boolean
  /** Whether the user has accepted the Privacy Policy */
  acceptPrivacyPolicies: boolean
  /** Callback to update Terms of Service acceptance state */
  setAcceptTos: (value: boolean) => void
  /** Callback to update Privacy Policy acceptance state */
  setAcceptPrivacyPolicies: (value: boolean) => void
  /** Callback to handle user consent submission */
  handleConsent: () => Promise<void>
  /**
   * Callback to handle external link presses
   * @param url - The URL path to append to the base URL
   * @param trackingName - Name for analytics tracking
   * @param screenName - Optional screen name for analytics context
   */
  handleLinkPress: (url: string, trackingName: string, screenName?: string) => void
}

const StyledH2 = styled(H2, {
  color: "$text",
  fontWeight: "700",
  variants: {
    small: {
      true: {
        fontSize: "$8",
      },
      false: {
        fontSize: "$9",
      },
    },
  } as const,
})
const StyledText = styled(Text, {
  color: "$text",
  fontWeight: "400",
  variants: {
    small: {
      true: {
        fontSize: "$3",
      },
      false: {
        fontSize: "$4",
      },
    },
  } as const,
})

/**
 * Dialog component that displays Terms and Conditions and Privacy Policy acceptance UI
 * Handles user consent flows for both web and mobile platforms
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.consented - Whether user has already consented to terms
 * @param {TermsOfService} props.termsOfService - Current Terms of Service data
 * @param {boolean} props.acceptTos - Whether Terms of Service are accepted
 * @param {boolean} props.acceptPrivacyPolicies - Whether Privacy Policy is accepted
 * @param {(value: boolean) => void} props.setAcceptTos - Updates Terms acceptance state
 * @param {(value: boolean) => void} props.setAcceptPrivacyPolicies - Updates Privacy Policy acceptance state
 * @param {() => Promise<void>} props.handleConsent - Handles submission of user consent
 * @param {(url: string, trackingName: string, screenName?: string) => void} props.handleLinkPress - Handles external link presses
 * @returns {JSX.Element} Terms and conditions dialog
 */
const TermsDialog = memo(
  ({
    consented,
    termsOfService,
    acceptTos,
    acceptPrivacyPolicies,
    setAcceptTos,
    setAcceptPrivacyPolicies,
    handleConsent,
    handleLinkPress,
  }: TermsDialogProps) => {
    const { xs } = useMedia()

    return (
      <Dialog open={!consented} modal onOpenChange={() => {}}>
        <Dialog.Portal>
          <Dialog.Overlay key="overlay" backgroundColor="rgba(0, 0, 0, 0.5)" />
          <ShadowlessDialogContent
            key="content"
            maxWidth={Math.min(Dimensions.get("window").width * 0.8, 500)}
            paddingTop="$5"
            paddingLeft="$3.5"
            paddingRight="$3.5"
            paddingBottom="$3.5"
            borderRadius="$6"
            backgroundColor="$background"
          >
            <YStack gap="$4">
              <Dialog.Title>
                <StyledH2 small={xs}>Before you get started</StyledH2>
              </Dialog.Title>

              <YStack gap="$3">
                <StyledText small={xs}>
                  This AI-powered app is designed to offer educational health information, it is not
                  intended to provide medical advice. Conversations are AI-generated, not provided
                  by a human. Always consult a healthcare provider with any health-related
                  questions.
                </StyledText>

                <StyledText small={xs}>
                  In a medical emergency, call 911 or seek immediate care. Do not delay treatment
                  based on app advice.
                </StyledText>
              </YStack>

              <CheckboxWithLabel
                textId="acceptAll"
                checked={acceptTos && acceptPrivacyPolicies}
                onCheckedChange={(checked) => {
                  setAcceptTos(checked as boolean)
                  setAcceptPrivacyPolicies(checked as boolean)
                }}
                label={
                  <StyledText flex={1} flexWrap="wrap" color="$text" small={xs}>
                    I have read and accept the{" "}
                    <StyledText
                      color="$textLink"
                      onPress={() =>
                        handleLinkPress(CLOSEDLOOP_URL_CONFIG.PATHS.TERMS_OF_USE, "TermsOfUse")
                      }
                      small={xs}
                    >
                      Terms of Service
                    </StyledText>
                    ,{" "}
                    <StyledText
                      color="$textLink"
                      onPress={() =>
                        handleLinkPress(CLOSEDLOOP_URL_CONFIG.PATHS.PRIVACY_POLICY, "PrivacyPolicy")
                      }
                      small={xs}
                    >
                      Privacy Policy
                    </StyledText>
                    , and{" "}
                    <StyledText
                      color="$textLink"
                      onPress={() =>
                        handleLinkPress(
                          CLOSEDLOOP_URL_CONFIG.PATHS.MHMD_ACT,
                          "MHMDActHealthPrivacy",
                        )
                      }
                      small={xs}
                    >
                      Consumer Health Data Privacy Policy
                    </StyledText>
                    .
                  </StyledText>
                }
              />

              <Button
                variant="primary"
                borderRadius="$4"
                disabled={!acceptTos || !acceptPrivacyPolicies}
                onPress={() => {
                  handleConsent()
                  trackButtonClicked("Accept", "TermsAndConditions", {
                    tos_version: termsOfService?.version,
                  })
                }}
              >
                Get Started
              </Button>
            </YStack>
          </ShadowlessDialogContent>
        </Dialog.Portal>
      </Dialog>
    )
  },
)

/**
 * Props interface for the TermsAndConditions component.
 * Defines the required properties for managing user consent to terms and conditions.
 */
interface TermsAndConditionsProps {
  /** Whether the user has already consented to the terms */
  consented: boolean
  /** Callback to update the user's consent state */
  setConsented: (value: boolean) => void
  /** Child components to render after consent is given */
  children: React.ReactNode
}

/**
 * Component that handles the Terms and Conditions acceptance flow.
 * Displays a dialog with Terms of Service and Privacy Policy checkboxes that users must accept
 * before proceeding. Handles fetching the latest terms version and submitting user consent.
 *
 * @component
 * @param {TermsAndConditionsProps} props - Component props
 * @param {boolean} props.consented - Whether the user has already consented to the terms
 * @param {(value: boolean) => void} props.setConsented - Callback to update the user's consent state
 * @param {React.ReactNode} props.children - Child components to render after consent is given
 * @returns {JSX.Element} Terms and conditions dialog or children if already consented
 */
export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  consented,
  setConsented,
  children,
}) => {
  const { user } = useUserStore()
  const [loading, setLoading] = useState(true)
  const [termsOfService, setTermsOfService] = useState<TermsOfService>()
  const [acceptTos, setAcceptTos] = useState(false)
  const [acceptPrivacyPolicies, setAcceptPrivacyPolicies] = useState(false)
  const httpClient = useHttpClient()
  const { utmParameters, isLoading: isUtmLoading } = useUtm()

  mixpanel.trackOnMount(
    { page_name: "TermsAndConditions", ...formatUtmForMixpanel(utmParameters) },
    isUtmLoading,
  )

  const fetchTerms = useCallback(async () => {
    const tos = await httpClient.get<{ terms: TermsOfService }>(
      CLOSEDLOOP_URL_CONFIG.PATHS.TERMS_OF_SERVICE,
    )
    if (tos) {
      setLoading(false)
      setTermsOfService(tos?.terms)
    }
  }, [httpClient])

  const fetchConsent = useCallback(async () => {
    const userConsent = await httpClient.get<boolean>(
      CLOSEDLOOP_URL_CONFIG.PATHS.TERMS_OF_SERVICE_CONSENT,
    )
    if (userConsent) {
      setLoading(false)
      setConsented(true)
    } else {
      fetchTerms()
    }
  }, [fetchTerms, httpClient])

  useEffect(() => {
    if (!user) return
    fetchConsent()
  }, [user, fetchConsent])

  const handleConsent = useCallback(async () => {
    const updateConsent = await httpClient.post(
      CLOSEDLOOP_URL_CONFIG.PATHS.TERMS_OF_SERVICE_CONSENT_BY_ID(termsOfService!.id),
    )
    if (updateConsent) {
      setConsented(true)
    }
  }, [httpClient, setConsented, termsOfService])

  const handleLinkPress = useCallback(
    (url: string, trackingName: string, screenName: string = "TermsAndConditions") => {
      trackButtonClicked(trackingName, screenName)

      if (Platform.OS === "web") {
        window.open(CLOSEDLOOP_URL_CONFIG.BASE_URL + url, "_blank")
      } else {
        Linking.openURL(CLOSEDLOOP_URL_CONFIG.BASE_URL + url)
      }
    },
    [],
  )

  useKeyboardDismiss()

  return (
    <>
      {children}
      {!loading && (
        <TermsDialog
          consented={consented}
          termsOfService={termsOfService!}
          acceptTos={acceptTos}
          acceptPrivacyPolicies={acceptPrivacyPolicies}
          setAcceptTos={setAcceptTos}
          setAcceptPrivacyPolicies={setAcceptPrivacyPolicies}
          handleConsent={handleConsent}
          handleLinkPress={handleLinkPress}
        />
      )}
    </>
  )
}
