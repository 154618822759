import { createContext } from "react"

type IFeatureFlagContext = {
  readonly identify: (email: string) => void
}

const defaultFeatureFlagContext: IFeatureFlagContext = {
  identify: () => {
    throw new Error("Not implemented")
  },
}

export const FeatureFlagContext = createContext(defaultFeatureFlagContext)
