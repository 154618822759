import { AuthEvents, AuthProvider, HttpClientProvider } from "@my/api"
import { registerIcons } from "@my/config/src/icons"
import { config } from "@my/config/src/tamagui.config"
import { CustomToast } from "@my/ui"
import { ToastProvider, useToastController } from "@tamagui/toast"
import { logger } from "app/telemetry/logger"
import { useCallback, useEffect } from "react"
import { useColorScheme } from "react-native"
import { TamaguiProvider, TamaguiProviderProps } from "tamagui"
import { EventBus } from "../events"
import { FeatureFlagProvider } from "../feature-flags"
import { useLogout } from "../hooks/useLogout"
import { useClearFeatureFlags } from "../storage"
import { ToastViewport } from "./ToastViewport"

export function AppUiProvider({ children, ...rest }: Omit<TamaguiProviderProps, "config">) {
  const scheme = useColorScheme()

  useEffect(() => {
    registerIcons()
  }, [])

  return (
    <TamaguiProvider
      config={config}
      disableInjectCSS
      defaultTheme={scheme === "dark" ? "dark" : "light"}
      {...rest}
    >
      <ToastProvider swipeDirection="horizontal" duration={5000}>
        {children}

        <CustomToast />
        <ToastViewport />
      </ToastProvider>
    </TamaguiProvider>
  )
}

export function AppApiProvider({ children }: { children: React.ReactNode }) {
  const toast = useToastController()
  const { handleLogout } = useLogout()
  const clearFeatureFlags = useClearFeatureFlags()

  const onLogin = useCallback(() => {
    EventBus.emit(AuthEvents.Login)
  }, [])

  const onLogout = useCallback(async () => {
    await clearFeatureFlags()
    await EventBus.emitSync(AuthEvents.Logout)
  }, [])

  const onError = useCallback(
    (errorMessage: string) => toast.show("Error", { message: errorMessage }),
    [toast],
  )

  return (
    <FeatureFlagProvider>
      <AuthProvider onLogin={onLogin} onLogout={onLogout} logger={logger}>
        <HttpClientProvider onError={onError} logout={handleLogout}>
          {children}
        </HttpClientProvider>
      </AuthProvider>
    </FeatureFlagProvider>
  )
}
