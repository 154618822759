import { useMemo } from "react"
import { useAuth } from "../auth/useAuth"

import { createHttpClient, HttpClientContext, HttpClientOptions } from "./HttpClientContext"

export function HttpClientProvider({
  children,
  onError,
  logout,
}: { children: React.ReactNode } & HttpClientOptions): JSX.Element {
  const auth = useAuth()
  const options = useMemo(() => ({ onError, logout }), [onError, logout])
  const httpClient = useMemo(() => createHttpClient(auth, options), [auth, options])
  return <HttpClientContext.Provider value={httpClient}>{children}</HttpClientContext.Provider>
}
