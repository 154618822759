import { useEffect, useMemo, useState } from "react"
import { useStoredString } from "../storage"
import { UserUtmParameters } from "@my/api"
import { IUtmContext } from "./UtmContext"
import { parseUtmParameters } from "./parseUtmParameters"

/**
 * Hook to capture UTM ad campaign parameters from the URL, if present, and store in MMKV
 * Note: We want to keep the latest UTM values, *not* the first ones seen, so we should always overwrite
 * the existing values in storage (as long as one of the 5 parameters is non-null).
 */
export const useCaptureUtm = (): IUtmContext => {
  const [storedUtm, setStoredUtm] = useStoredString("utmParams", undefined)
  const [isLoading, setIsLoading] = useState(true)

  // Memoize URL UTM parameters
  const urlUtmParams = useMemo(() => {
    return parseUtmParameters(window.location.search)
  }, [])

  // Store URL UTM parameters on mount
  useEffect(() => {
    if (urlUtmParams && storedUtm !== JSON.stringify(urlUtmParams)) {
      setStoredUtm(JSON.stringify(urlUtmParams))
    }
    setIsLoading(false)
  }, [urlUtmParams, storedUtm])

  return useMemo(
    () => ({
      utmParameters: storedUtm ? (JSON.parse(storedUtm) as UserUtmParameters) : undefined,
      isLoading,
      setUtmParameters: (queryString: string) => {
        const newUtmParams = parseUtmParameters(queryString)
        if (newUtmParams && storedUtm !== JSON.stringify(newUtmParams)) {
          setStoredUtm(JSON.stringify(newUtmParams))
        }
      },
      parseUtmParameters,
    }),
    [storedUtm, isLoading, setStoredUtm],
  )
}
