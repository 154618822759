import { forwardRef } from "react"
import {
  AlertDialogContent,
  AlertDialogContentProps,
  DialogContent,
  DialogContentProps,
  TamaguiElement,
} from "tamagui"

export const ShadowlessDialogContent = forwardRef<TamaguiElement, DialogContentProps>(
  (props, ref) => {
    return (
      <DialogContent
        shadowColor="transparent"
        shadowOpacity={0}
        shadowOffset={{ width: 0, height: 0 }}
        {...props}
        ref={ref}
      />
    )
  },
)

export const ShadowlessAlertDialogContent = forwardRef<TamaguiElement, AlertDialogContentProps>(
  (props, ref) => {
    return (
      <AlertDialogContent
        shadowColor="transparent"
        shadowOpacity={0}
        shadowOffset={{ width: 0, height: 0 }}
        {...props}
        ref={ref}
      />
    )
  },
)
