import { styled, Button as TamaguiButton } from "tamagui"

const baseStyles = {
  fontSize: "$5",
  fontWeight: "500",
  cursor: "pointer",

  disabledStyle: {
    cursor: "not-allowed",
  },
} as const

export const Button = styled(TamaguiButton, {
  name: "Button",
  ...baseStyles,
  variants: {
    variant: {
      primary: {
        backgroundColor: "$buttonPrimary",
        color: "$buttonPrimaryText",
        borderWidth: 0,
        hoverStyle: {
          backgroundColor: "$buttonPrimaryHover",
        },
        pressStyle: {
          backgroundColor: "$buttonPrimaryPress",
        },
        focusStyle: {
          backgroundColor: "$buttonPrimaryFocus",
        },
        disabledStyle: {
          backgroundColor: "$buttonPrimaryDisabled",
        },
      },
      secondary: {
        backgroundColor: "$buttonSecondary",
        color: "$buttonSecondaryText",
        borderWidth: 0,
        hoverStyle: {
          backgroundColor: "$buttonSecondaryHover",
        },
        pressStyle: {
          backgroundColor: "$buttonSecondaryPress",
        },
        focusStyle: {
          backgroundColor: "$buttonSecondaryFocus",
        },
        disabledStyle: {
          backgroundColor: "$buttonSecondaryDisabled",
        },
      },
      tertiary: {
        backgroundColor: "$buttonTertiary",
        color: "$buttonTertiaryText",
        borderWidth: 0,
        hoverStyle: {
          backgroundColor: "$buttonTertiaryHover",
        },
        pressStyle: {
          backgroundColor: "$buttonTertiaryPress",
        },
        focusStyle: {
          backgroundColor: "$buttonTertiaryFocus",
        },
        disabledStyle: {
          backgroundColor: "$buttonTertiaryDisabled",
        },
      },
    },
  } as const,
})
