import { UserUtmParameters } from "@my/api"

export const formatUtmForMixpanel = (utm: UserUtmParameters | undefined) => {
  return {
    utm_source: utm?.utmSource,
    utm_medium: utm?.utmMedium,
    utm_campaign: utm?.utmCampaign,
    utm_term: utm?.utmTerm,
    utm_content: utm?.utmContent,
  }
}
