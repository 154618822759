import { useStoredBoolean } from "app/storage"
import { FirstTimeUserExperience } from "./FirstTimeUserExperience"
import { TermsAndConditions } from "./TermsAndConditions"
import { LoginFlow } from "./LoginFlow"
import { isWeb } from "tamagui"
import { useUserStore } from "app/global-state/userState"
import { TimezoneListener } from "./TimezoneListener"
import { useAuth } from "@my/api"
import { useLogout } from "app/hooks"

export const StartupFlow = ({ children }: { children: React.ReactNode }) => {
  const [hasCompletedFtue, setHasCompletedFtue] = useStoredBoolean("has-completed-ftue", false)
  const { tosAccepted, setTosAccepted } = useUserStore()
  const { isAuthenticated } = useAuth()
  const { handleLogout } = useLogout()

  if (!hasCompletedFtue && isWeb) {
    return (
      <LoginFlow>
        <FirstTimeUserExperience onComplete={() => setHasCompletedFtue(true)} />
        <TimezoneListener>{children}</TimezoneListener>
      </LoginFlow>
    )
  } else if (!hasCompletedFtue && !isWeb) {
    // If you haven't gone through FTUE on mobile but you are already logged in,
    // this is a leftover token from an old install. We need to logout that before continuing.
    //
    // Note: hasCompletedFtue will be undefined transiently on load; we need to make sure it is exactly 'false'
    // if we want to verify that MMKV resolved and the key is not present.
    if (hasCompletedFtue === false && isAuthenticated) {
      handleLogout()
    }
    return <FirstTimeUserExperience onComplete={() => setHasCompletedFtue(true)} />
  }
  return (
    <LoginFlow>
      {!tosAccepted ?
        <TermsAndConditions consented={tosAccepted} setConsented={setTosAccepted}>
          <TimezoneListener>{children}</TimezoneListener>
        </TermsAndConditions>
      : <TimezoneListener>{children}</TimezoneListener>}
    </LoginFlow>
  )
}
