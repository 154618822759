/**
 * A series of utility functions to help with formatting strings.
 */
export const changeCase = {
  /**
   * Capitalizes the first letter of each word in the string.
   *
   * @param {string} str - The string to capitalize.
   * @returns {string} The capitalized string.
   */
  capitalize: (str: string) => str.replace(/\b\w/g, (char) => char.toUpperCase()),

  /**
   * Converts the entire string to uppercase.
   *
   * @param {string} str - The string to convert to uppercase.
   * @returns {string} The uppercase string.
   */
  uppercase: (str: string) => str.toUpperCase(),

  /**
   * Converts the entire string to lowercase.
   *
   * @param {string} str - The string to convert to lowercase.
   * @returns {string} The lowercase string.
   */
  lowercase: (str: string) => str.toLowerCase(),

  /**
   * Converts the string to snake_case.
   *
   * @param {string} str - The string to convert to snake_case.
   * @returns {string} The snake_case string.
   */
  snake: (str: string) => str.replace(/\s+/g, "_").toLowerCase(),

  /**
   * Converts the string to camelCase.
   *
   * @param {string} str - The string to convert to camelCase.
   * @returns {string} The camelCase string.
   */
  camel: (str: string) =>
    str
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toLowerCase() : match.toUpperCase(),
      )
      .replace(/\s+/g, ""),

  /**
   * Converts the string to PascalCase.
   *
   * @param {string} str - The string to convert to PascalCase.
   * @returns {string} The PascalCase string.
   */
  pascal: (str: string) =>
    str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match) => match.toUpperCase()).replace(/\s+/g, ""),

  /**
   * Converts the string to sentence case. The first letter of the first word is capitalized, and the rest is in lowercase.
   *
   * @param {string} str - The string to convert to sentence case.
   * @returns {string} The sentence case string.
   */
  sentence: (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(),

  /**
   * Converts the string to Title Case. The first letter of each word is capitalized.
   *
   * @param {string} str - The string to convert to Title Case.
   * @returns {string} The Title Case string.
   */
  title: (str: string) => str.replace(/\b\w/g, (char) => char.toUpperCase()),

  /**
   * Converts a camelCase string to a space-separated string with only the first word capitalized.
   *
   * @param {string} str - The camelCase string to convert.
   * @returns {string} The converted string.
   */
  camelToSentence: (str: string) => {
    const spacedString = str.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase()
    return changeCase.sentence(spacedString)
  },

  /**
   * Converts a camelCase string to a display sentence with each word capitalized.
   *
   * @param {string} str - The camelCase string to convert.
   * @returns {string} The display sentence string.
   */
  camelCaseToTitleCase: (str: string) => {
    // Convert camelCase to spaced words
    const spacedString = str.replace(/([a-z])([A-Z])/g, "$1 $2")

    // Capitalize the first letter of each word
    const displaySentence = spacedString
      .split(" ")
      .map((word) => changeCase.capitalize(word))
      .join(" ")

    return displaySentence
  },

  /**
   * Converts from sentence into dash case. E.g.
   * "Hello World" to "hello-world"
   * @param str
   * @return the converted string
   */
  dashCase: (str: string) => {
    return str
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "") // Removes start and end hypens
  },

  /**
   * Returns the string literal of the given string unchanged.
   *
   * @param {string} str - The string to return unchanged.
   * @returns {string} The unchanged string.
   */
  none: (str: string) => `${str}`,
}
