import { User } from "@my/api"
import { create } from "./create"

export const useUserStore = create<{
  user: User | null
  setUser: (user: User | null) => void
  tosAccepted: boolean
  setTosAccepted: (Accepted: boolean) => void
}>((set) => ({
  user: null,
  setUser: (user: User | null) =>
    set({
      user:
        user ?
          {
            ...user,
            initials: `${user?.givenName?.[0] ?? ""}${user?.familyName?.[0] ?? ""}`,
          }
        : null,
    }),
  tosAccepted: false,
  setTosAccepted: (accepted: boolean) => set({ tosAccepted: accepted }),
}))
