export type Permission = "admin" | "debug" | "upload-health-data"

export const BLOOD_TYPES = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"] as const
export type BloodType = (typeof BLOOD_TYPES)[number]

/**
 * X – Roughly 1 in 2,000 to 1 in 5,000 people (Turner’s )
 * XX – Most common form of female
 * XXY – Roughly 1 in 500 to 1 in 1,000 people (Klinefelter)
 * XY – Most common form of male
 * XYY – Roughly 1 out of 1,000 people
 * XXXY – Roughly 1 in 18,000 to 1 in 50,000 births
 */
export const CHROMOSOMES = ["X", "XX", "XY", "XYY", "XXY", "XXXY"] as const
export type Chromosome = (typeof CHROMOSOMES)[number]

/**
 * case I - Pale white skin that always burns easily in the sun and never tans.
 * case II - White skin that burns easily and tans minimally.
 * case III - White to light brown skin that burns moderately and tans uniformly.
 * case IV - Beige-olive, lightly tanned skin that burns minimally and tans moderately.
 * case V - Brown skin that rarely burns and tans profusely.
 * case VI - Dark brown to black skin that never burns and tans profusely.
 */
export const FITZPATRICK_SKIN_TYPES = ["I", "II", "III", "IV", "V", "VI"] as const
export type FitzpatrickSkinType = (typeof FITZPATRICK_SKIN_TYPES)[number]

export const GENDER_IDENTITIES = [
  "female",
  "male",
  "non-binary",
  "transgender female",
  "transgender male",
  "genderfluid",
  "intersex",
  "androgynous",
  "other",
] as const
export type GenderIdentity = (typeof GENDER_IDENTITIES)[number]

export const SEXES = ["female", "male", "intersex", "other"] as const
export type Sex = (typeof SEXES)[number]

export interface HeightUnits {
  name: "centimeters" | "inches"
  abbreviation: "cm" | "in"
}
export const HEIGHT_UNITS: HeightUnits[] = [
  {
    name: "centimeters",
    abbreviation: "cm",
  },
  {
    name: "inches",
    abbreviation: "in",
  },
] as const

export interface WeightUnits {
  name: "kilograms" | "pounds" | "stone" | "ounces"
  abbreviation: "kg" | "lbs" | "st" | "oz"
}
export const WEIGHT_UNITS: WeightUnits[] = [
  {
    name: "kilograms",
    abbreviation: "kg",
  },
  {
    name: "pounds",
    abbreviation: "lbs",
  },
  {
    name: "stone",
    abbreviation: "st",
  },
  {
    name: "ounces",
    abbreviation: "oz",
  },
] as const

/**
 * The given user profile schema should align with the users_schema in the services repo.
 *
 * @see https://bitbucket.org/closedloopai/astoria-service/src/main/src/astoria_service/schemas/users_schemas.py
 */
export type User = {
  id: string
  auth0Id: string
  createdAt: Date
  updatedAt: Date
  email: string
  givenName: string
  familyName: string
  initials?: string
  picture?: string | null
  birthDate?: string | null // TODO: date? not currently since it's only a date and not a full timestamp.
  sex?: Sex | null
  gender?: GenderIdentity | null
  bloodType?: BloodType | null
  height?: number | null
  weight?: number | null
  timeZone?: string | null
  wheelchairUser?: boolean | null
  skinType?: FitzpatrickSkinType | null
  utm?: UserUtmParameters | null
}

export type UserUtmParameters = {
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmTerm?: string
  utmContent?: string
}
