export enum HealthCategory {
  BodyMeasurements = "bodyMeasurements",
  Symptoms = "symptoms",
  Sleep = "sleep",
  Moods = "moods",
  Food = "food",
}

const healthCategoryPathParamLookup: Record<HealthCategory, string> = {
  [HealthCategory.BodyMeasurements]: "body-measurements",
  [HealthCategory.Symptoms]: "symptoms",
  [HealthCategory.Sleep]: "sleep",
  [HealthCategory.Moods]: "moods",
  [HealthCategory.Food]: "food",
}

export function healthCategoryToPathParam(enumValue: HealthCategory): string {
  return healthCategoryPathParamLookup[enumValue]
}

export type SubcategoryDetails = {
  id: string
  dataType: HealthValueDataType
  displayName: string
  units?: string
}
export type HealthCategoriesResponse = {
  id: string
  name: HealthCategory
  subcategories: SubcategoryDetails[]
  subcategoryIds: string[]
}[]

export enum HealthValueDataType {
  Float = "float",
  Int = "int",
  Text = "text",
  Bool = "bool",
}

export type HealthCategorySummaryResponse = {
  id: string
  displayName: string
  dataType: HealthValueDataType
  units?: string
  dataPoints: HealthDataPoint[]
}[]

export enum HealthFactSource {
  Chat = "HealthCoach Chat",
  Form = "HealthCoach Form",
  Checkin = "HealthCoach Checkin",
  AppleHealth = "Apple Health",
}

export type HealthDataPoint = CreateHealthDataPoint & {
  id: string
  messageId?: string
  mentionText?: string
}

export type EntityResponse = {
  dataPoints: HealthDataPoint[]
}
export type CreateHealthDataPoint = {
  confirmedAt: Date | null
  endedAt: Date | null
  source: HealthFactSource
  startedAt: Date | null
  subcategoryId: string
  timestamp: Date | null
  value: string | number | boolean
}

export const defaultHealthDataPoint = {
  id: "",
  confirmedAt: null,
  endedAt: null,
  source: HealthFactSource.Form,
  startedAt: null,
  subcategoryId: "",
  timestamp: null,
  value: null,
}

export type HealthSubcategoryDataResponse = {
  id: string
  dataPoints: HealthDataPoint[]
  dataType: HealthValueDataType
  description: string
  displayName: string
  units?: string
}

export enum SymptomSeverity {
  NotPresent = "notPresent",
  Unspecified = "unspecified",
  Mild = "mild",
  Moderate = "moderate",
  Severe = "severe",
}

export enum SleepValue {
  InBed = "inBed",
  Asleep = "asleepUnspecified",
  Awake = "awake",
  Core = "asleepCore",
  Deep = "asleepDeep",
  REM = "asleepREM",
}

export enum MoodIntensity {
  Present = "present",
  Low = "low",
  Medium = "medium",
  High = "high",
}
