import { createContext } from "react"
import { UserUtmParameters } from "@my/api"

/**
 * Interface defining the UTM context shape used for tracking marketing campaign parameters
 * across the application.
 */
export interface IUtmContext {
  /** The current UTM parameters stored for the user, if any exist */
  utmParameters?: UserUtmParameters
  /** Indicates if the UTM context is still initializing */
  isLoading: boolean
  /**
   * Function to parse and store new UTM parameters from a referrer string.
   * @param referrer - URL-formatted string containing UTM parameters (e.g. "utm_source=google&utm_medium=cpc")
   */
  setUtmParameters: (referrer: string) => void
  /**
   * Function to parse UTM parameters from a referrer string without storing them
   * @param referrer - URL-formatted string containing UTM parameters
   * @returns UserUtmParameters object or undefined if parsing fails
   */
  parseUtmParameters: (referrer: string) => UserUtmParameters | undefined
}

const defaultUtmContext: IUtmContext = {
  utmParameters: undefined,
  isLoading: false,
  setUtmParameters: () => {
    throw new Error("setUtmParameters is not implemented")
  },
  parseUtmParameters: () => {
    throw new Error("parseUtmParameters is not implemented")
  },
}

export const UtmContext = createContext<IUtmContext>(defaultUtmContext)
