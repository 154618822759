import { Routes } from "../../routing"
import { TabBar, TabProps } from "./components/TabBar"

const tabs: TabProps[] = [
  { value: Routes.chat, label: "Chat", iconName: "comment-lines" },
  { value: Routes.profile, label: "Profile", iconName: "user-circle" },
  { value: Routes.settings, label: "Settings", iconName: "cog" },
]

export function TabNavigator({ children }: { children: React.ReactNode }) {
  return <TabBar tabs={tabs}>{children}</TabBar>
}
