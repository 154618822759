export const CLOSEDLOOP_URL_CONFIG = {
  BASE_URL: "https://www.closedloop.ai/",
  PATHS: {
    TERMS_OF_USE: "/hc-terms-of-use/",
    TERMS_OF_SERVICE: "/terms-of-service",
    TERMS_OF_SERVICE_CONSENT: "/terms-of-service/consented",
    TERMS_OF_SERVICE_CONSENT_BY_ID: (id: string) => `/terms-of-service/${id}/consent`,
    PRIVACY_POLICY: "/consumer-privacy-policy/",
    MHMD_ACT: "/mhmd-act-consumer-health-privacy/",
  },
} as const
