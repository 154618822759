import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faAddressCard as farAddressCard,
  faArrowLeft as farArrowLeft,
  faBars as farBars,
  faBedFront as farBedFront,
  faCalendar as farCalendar,
  faCheck as farCheck,
  faChevronDown as farChevronDown,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronUp as farChevronUp,
  faCircleArrowUp as farCircleArrowUp,
  faCircleCheck as farCircleCheck,
  faCircleExclamation as farCircleExclamation,
  faCircleInfo as farCircleInfo,
  faCircleQuestion as farCircleQuestion,
  faCircleX as farCircleX,
  faClipboardMedical as farClipboardMedical,
  faClock as farClock,
  faCog as farCog,
  faCommentCheck as farCommentCheck,
  faCommentLines as farCommentLines,
  faCopy as farCopy,
  faEclipse as farEclipse,
  faEdit as farEdit,
  faEllipsisVertical as farEllipsisVertical,
  faEnvelope as farEnvelope,
  faGauge as farGauge,
  faHeart as farHeart,
  faPalette as farPalette,
  faPlateUtensils as farPlateUtensils,
  faServer as farServer,
  faSidebar as farSidebar,
  faSidebarFlip as farSidebarFlip,
  faSliders as farSliders,
  faStethoscope as farStethoscope,
  faThumbsDown as farThumbsDown,
  faThumbsUp as farThumbsUp,
  faUser as farUser,
  faUserCircle as farUserCircle,
  faWeight as farWeight,
  faX as farX,
  faPlus as farPlus,
  faCaretLeft as farCaretLeft,
  faCaretRight as farCaretRight,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faAddressCard as fasAddressCard,
  faArrowLeft as fasArrowLeft,
  faBars as fasBars,
  faBedFront as fasBedFront,
  faCalendar as fasCalendar,
  faCheck as fasCheck,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircleArrowUp as fasCircleArrowUp,
  faCircleCheck as fasCircleCheck,
  faCircleExclamation as fasCircleExclamation,
  faCircleInfo as fasCircleInfo,
  faCircleQuestion as fasCircleQuestion,
  faCircleX as fasCircleX,
  faClipboardMedical as fasClipboardMedical,
  faClock as fasClock,
  faCog as fasCog,
  faCommentCheck as fasCommentCheck,
  faCommentLines as fasCommentLines,
  faCopy as fasCopy,
  faEclipse as fasEclipse,
  faEdit as fasEdit,
  faEllipsisVertical as fasEllipsisVertical,
  faEnvelope as fasEnvelope,
  faGauge as fasGauge,
  faHeart as fasHeart,
  faPalette as fasPalette,
  faPlateUtensils as fasPlateUtensils,
  faServer as fasServer,
  faSidebar as fasSidebar,
  faSidebarFlip as fasSidebarFlip,
  faSliders as fasSliders,
  faStethoscope as fasStethoscope,
  faThumbsDown as fasThumbsDown,
  faThumbsUp as fasThumbsUp,
  faUser as fasUser,
  faUserCircle as fasUserCircle,
  faWeight as fasWeight,
  faX as fasX,
  faPlus as fasPlus,
  faCaretLeft as fasCaretLeft,
  faCaretRight as fasCaretRight,
} from "@fortawesome/pro-solid-svg-icons"

export function registerIcons() {
  library.add(
    farAddressCard,
    fasAddressCard,
    farArrowLeft,
    fasArrowLeft,
    farBars,
    fasBars,
    farBedFront,
    fasBedFront,
    farCalendar,
    fasCalendar,
    farCheck,
    fasCheck,
    farChevronDown,
    fasChevronDown,
    farChevronLeft,
    fasChevronLeft,
    farChevronRight,
    fasChevronRight,
    farChevronUp,
    fasChevronUp,
    farCircleArrowUp,
    fasCircleArrowUp,
    farCircleCheck,
    fasCircleCheck,
    farCircleExclamation,
    fasCircleExclamation,
    farCircleInfo,
    fasCircleInfo,
    farCircleQuestion,
    fasCircleQuestion,
    farCircleX,
    fasCircleX,
    farClipboardMedical,
    fasClipboardMedical,
    farClock,
    fasClock,
    farCog,
    fasCog,
    farCommentCheck,
    fasCommentCheck,
    farCommentLines,
    fasCommentLines,
    farCopy,
    fasCopy,
    farEclipse,
    fasEclipse,
    farEdit,
    fasEdit,
    farEllipsisVertical,
    fasEllipsisVertical,
    farEnvelope,
    fasEnvelope,
    farGauge,
    fasGauge,
    farHeart,
    fasHeart,
    farPalette,
    fasPalette,
    farPlateUtensils,
    fasPlateUtensils,
    farServer,
    fasServer,
    farSidebar,
    fasSidebar,
    farSidebarFlip,
    fasSidebarFlip,
    farStethoscope,
    fasStethoscope,
    farThumbsDown,
    fasThumbsDown,
    farThumbsUp,
    fasThumbsUp,
    farUser,
    fasUser,
    farUserCircle,
    fasUserCircle,
    farWeight,
    fasWeight,
    farX,
    fasX,
    farArrowLeft,
    fasArrowLeft,
    farSliders,
    fasSliders,
    farPlus,
    fasPlus,
    farCaretLeft,
    fasCaretLeft,
    farCaretRight,
    fasCaretRight,
  )
}
