import {
  blue,
  blueDark,
  green,
  greenDark,
  orange,
  orangeDark,
  red,
  redDark,
  slate,
  slateDark,
} from "@tamagui/colors"
import { radius, zIndex, space, size } from "@tamagui/themes"
import { createTokens } from "@tamagui/web"

/**
 * The color tokens from Tamagui come via the radix ui color scales.
 * Radix has the color palettes for both light and dark modes that are used at
 * the same color number but render an inverted color.
 *
 * @see https://www.radix-ui.com/colors/docs/palette-composition/scales
 * TODO: Would be nice to have a check here of the current theme and then use
 *       the appropriate color tokens in the colorTokens.
 */
export const color = {
  ...blue,
  ...slate,
  ...green,
  ...orange,
  ...red,
} as const

export const colorDark = {
  ...blueDark,
  ...slateDark,
  ...greenDark,
  ...orangeDark,
  ...redDark,
} as const

export const tokens = createTokens({
  color,
  colorDark,
  radius,
  zIndex,
  space: {
    xl: space["$9"],
    lg: space["$7"],
    base: space["$5"],
    sm: space["$3"],
    xs: space["$2"],
    xxs: space["$1.5"],
    mini: space["$1"],
    ...space,
  },
  size,
})
