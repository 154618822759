import { User } from "@my/api"
import { setDatadogUser } from "./datadog"
import { mixpanel } from "./mixpanel"
import { useState, useEffect, useRef } from "react"
import { useNetInfoInstance } from "@react-native-community/netinfo"

export * from "./datadog"
export * from "./mixpanel"

export function setMonitoringUser(user: User) {
  setDatadogUser(user)
  mixpanel.identify(user.auth0Id)
  mixpanel.setSuperProperties({
    first_seen: user.createdAt,
  })
  mixpanel.setProfile({
    first_seen: user.createdAt,
    user_utm_source: user.utm?.utmSource,
    user_utm_medium: user.utm?.utmMedium,
    user_utm_campaign: user.utm?.utmCampaign,
    user_utm_content: user.utm?.utmContent,
    user_utm_term: user.utm?.utmTerm,
    $email: user.email,
  })
}

export const trackButtonClicked = (
  buttonName: string,
  pageName: string,
  additionalProperties: { [key: string]: any } = {},
) => {
  mixpanel.track("Button Clicked", {
    button_name: buttonName,
    page_name: pageName,
    ...additionalProperties,
  })
}

/**
 * Tracks when the app is installed by a user.
 *
 * @param additionalProperties - Optional object containing additional properties to track with the install event
 *
 * @example
 * ```ts
 * trackAppInstall({ utm_source: 'google', utm_medium: 'cpc' })
 * ```
 */
export const trackAppInstall = (additionalProperties: Record<string, any> = {}) => {
  mixpanel.track("App Install", additionalProperties)
}

/**
 * Custom hook to retrieve and manage the Mixpanel distinct ID for user tracking.
 * Makes multiple attempts to fetch the ID with timeout protection.
 *
 * The distinct ID is used to:
 * - Track anonymous users before they authenticate
 * - Merge anonymous and authenticated user identities
 * - Ensure consistent user tracking across sessions
 *
 * @returns The Mixpanel distinct ID if successfully retrieved, undefined otherwise
 *
 * @example
 * ```ts
 * const distinctId = useDistinctId()
 * if (distinctId) {
 *   // Use distinctId for tracking
 * }
 * ```
 */
export function useDistinctId() {
  const [distinctId, setDistinctId] = useState<string | undefined>(undefined)
  const [attempts, setAttempts] = useState(0)
  const {
    netInfo: { isInternetReachable },
  } = useNetInfoInstance()
  const MAX_ATTEMPTS = 3
  const previousDistinctIdRef = useRef<string | undefined>(undefined)

  const fetchDistinctId = async () => {
    try {
      let timeoutId: NodeJS.Timeout
      const timeoutPromise = new Promise<undefined>((resolve) => {
        timeoutId = setTimeout(() => resolve(undefined), 5000)
      })

      const id = await Promise.race([mixpanel.getDistinctId(), timeoutPromise])

      clearTimeout(timeoutId!)

      if (id && id !== previousDistinctIdRef.current) {
        setDistinctId(id)
        previousDistinctIdRef.current = id
      } else if (!id) {
        setAttempts((prev) => prev + 1)
      }
    } catch (error) {
      setAttempts((prev) => prev + 1)
    }
  }

  useEffect(() => {
    let mounted = true

    if (distinctId || attempts >= MAX_ATTEMPTS || isInternetReachable === false) return

    const execute = async () => {
      if (mounted) {
        await fetchDistinctId()
      }
    }

    execute()

    return () => {
      mounted = false
    }
  }, [distinctId, attempts, isInternetReachable])

  return distinctId
}
