import type { IEnvironment } from "./environment"
import { withProtocol, Protocol } from "./protocol"

export const Environment: IEnvironment = {
  ENV: process.env.NEXT_PUBLIC_ENV!,
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN!,
  AUTH0_NATIVE_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_NATIVE_CLIENT_ID!,
  AUTH0_WEB_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_WEB_CLIENT_ID!,
  AUTH0_AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
  AUTH0_SCOPE: "openid profile email offline_access",
  AUTH0_REDIRECT_URI: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
  COMMIT_SHA: process.env.NEXT_PUBLIC_COMMIT_SHA!,
  DATADOG_APP_ID: process.env.NEXT_PUBLIC_DATADOG_APP_ID!,
  DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
  LAUNCH_DARKLY_KEY: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_KEY!,
  MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!,
  SERVER_HOST: process.env.NEXT_PUBLIC_SERVER_HOST!,
  SERVER_URL: withProtocol(process.env.NEXT_PUBLIC_SERVER_HOST, Protocol.HTTP)!,
  SERVER_WS_URL: withProtocol(process.env.NEXT_PUBLIC_SERVER_HOST, Protocol.WS)!,
}

export function setEnvironment(env: Partial<IEnvironment>) {
  for (const key in env) {
    const typedKey = key as keyof IEnvironment
    Environment[typedKey] = Environment[typedKey] || env[typedKey]!
  }
}
