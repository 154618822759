/**
 * Global debug flag for the application.
 *
 * When set to true, it enables debug mode across the application,
 * allowing debug messages to be logged and potentially enabling
 * other debug-related features.
 *
 * This flag is used by functions like `writeToDebug` to determine
 * whether to output debug information.
 *
 * @type {boolean}
 * @default true
 */
// This enables debug mode when set to true
export const DEBUG: boolean = false

/**
 * Logs debug information to the console if the provided debug flag is true.
 *
 * This function is used for debugging purposes throughout the application.
 * It only logs the information when the provided debug flag (or the global DEBUG flag if not provided)
 * is set to true, allowing for easy toggling of debug output.
 *
 * @param {boolean} [debugFlag=DEBUG] - Optional debug flag to control logging. Defaults to global DEBUG value.
 * @param {...any[]} args - Any number of arguments to be logged.
 * @example
 * writeToDebug(true, "User logged in", user);
 * writeToDebug(DEBUG_SOCKET, "API response", response);
 */
export function writeToDebug(...args: any[]) {
  if (DEBUG) {
    console.debug(...args)
  }
}
