import "@fortawesome/fontawesome-svg-core/styles.css"
import "@tamagui/font-inter/css/100.css"
import "@tamagui/font-inter/css/200.css"
import "@tamagui/font-inter/css/300.css"
import "@tamagui/font-inter/css/400.css"
import "@tamagui/font-inter/css/500.css"
import "@tamagui/font-inter/css/600.css"
import "@tamagui/font-inter/css/700.css"
import "@tamagui/font-inter/css/800.css"
import "@tamagui/font-inter/css/900.css"
import "raf/polyfill"
import "../reset.css"

import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react"
import { config as fontAwesomeConfig } from "@fortawesome/fontawesome-svg-core"
import { Environment, setEnvironment } from "@my/config/src/environment"
import { ColorScheme, NextThemeProvider, useRootTheme } from "@tamagui/next-theme"
import { ErrorBoundaryFallback } from "app/error-handling"
import { useFetchThreadsIntoStore } from "app/features/chat"
import { StartupFlow } from "app/features/startup"
import { TabNavigator } from "app/features/tabs"
import { AppApiProvider, AppUiProvider } from "app/provider"
import { useStorage } from "app/storage"
import { initDatadogRUM, mixpanel } from "app/telemetry"
import { withLDProvider } from "launchdarkly-react-client-sdk"
import Head from "next/head"
import { FC, useEffect, useMemo, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { SolitoAppProps } from "solito"
import { Spinner, Text, View } from "tamagui"
import { UtmProvider } from "app/utm/UtmProvider"

if (process.env.NODE_ENV === "production") {
  require("../../public/tamagui.css")
}

// https://docs.fontawesome.com/web/use-with/react/use-with#nextjs
fontAwesomeConfig.autoAddCss = false

export default function MyApp({ Component, pageProps }: SolitoAppProps) {
  const getLayout = Component.getLayout ?? ((c) => c)

  useEffect(() => {
    console.debug(Environment.COMMIT_SHA)
  }, [])

  return (
    <>
      <Head>
        <title>Healthy</title>
        <meta charSet="utf-8" />
        {/* the meta tag below prevents user from overscrolling while keyboard is closed on mobile web */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=0, maximum-scale=1, viewport-fit=cover"
        />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="shortcut icon" type="image/png" href="/favicon.png" sizes="any" />
      </Head>
      <ThemeProvider>
        <ConfigFetcher>
          <MyAuth0Provider>
            <AppApiProviderWithFF>
              <RootLayout>
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  {getLayout(<Component {...pageProps} />)}
                </ErrorBoundary>
              </RootLayout>
            </AppApiProviderWithFF>
          </MyAuth0Provider>
        </ConfigFetcher>
      </ThemeProvider>
    </>
  )
}

function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useRootTheme()

  return (
    <NextThemeProvider
      onChangeTheme={(next) => {
        setTheme(next as ColorScheme)
      }}
    >
      <AppUiProvider disableRootThemeClass defaultTheme={theme}>
        {children}
      </AppUiProvider>
    </NextThemeProvider>
  )
}

function ConfigFetcher({ children }: { children: React.ReactNode }) {
  const [config, setConfig] = useState<Partial<typeof Environment> | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [envSet, setEnvSet] = useState(false)

  useEffect(() => {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      setConfig({})
    } else {
      fetch(`${window.location.origin}/api/v1/config`)
        .then((res) => res.json())
        .then(setConfig)
        .catch((err) => setError(err))
    }
  }, [])

  useEffect(() => {
    if (config) {
      setEnvironment(mapConfigToEnvironment(config))
      setEnvSet(true)

      mixpanel.init()
      initDatadogRUM()
    }
  }, [config])

  if (envSet) {
    return children
  }

  if (error) {
    return (
      <View flex={1} padding="$base" alignItems="flex-start" gap="$xs">
        <Text>The Healthy app is currently unavailable. Please try again later.</Text>
        <Text>{error.message}</Text>
      </View>
    )
  }

  return (
    <View flex={1} padding="$base" alignItems="flex-start">
      <Spinner />
    </View>
  )
}

function mapConfigToEnvironment(config: any): Partial<typeof Environment> {
  return {
    ENV: config.env,
    AUTH0_DOMAIN: config.auth0Domain,
    AUTH0_NATIVE_CLIENT_ID: config.auth0NativeClientId,
    AUTH0_WEB_CLIENT_ID: config.auth0WebClientId,
    AUTH0_AUDIENCE: config.auth0Audience,
    AUTH0_REDIRECT_URI: config.auth0Redirect,
    DATADOG_APP_ID: config.datadogAppId,
    DATADOG_CLIENT_TOKEN: config.datadogClientToken,
    LAUNCH_DARKLY_KEY: config.launchdarklyKey,
    MIXPANEL_TOKEN: config.mixpanelToken,
    SERVER_HOST: config.serverHost,
    SERVER_URL: `https://${config.serverHost}`,
    SERVER_WS_URL: `wss://${config.serverHost}`,
  }
}

function MyAuth0Provider({ children }: { children: React.ReactNode }) {
  const storage = useStorage()

  return (
    <Auth0Provider
      domain={Environment.AUTH0_DOMAIN}
      clientId={Environment.AUTH0_WEB_CLIENT_ID}
      authorizationParams={{
        redirect_uri: Environment.AUTH0_REDIRECT_URI,
        audience: Environment.AUTH0_AUDIENCE,
        scope: Environment.AUTH0_SCOPE,
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cache={{
        set: (key, value) => storage.set(key, JSON.stringify(value)),
        get: (key) => {
          const value = storage.getString(key)
          return value ? JSON.parse(value) : undefined
        },
        remove: (key) => storage.delete(key),
      }}
    >
      {children}
    </Auth0Provider>
  )
}

function AppApiProviderWithFF({ children }: { children: React.ReactNode }) {
  const Provider = useMemo(() => {
    return withLDProvider({
      clientSideID: Environment.LAUNCH_DARKLY_KEY,
      reactOptions: { useCamelCaseFlagKeys: false },
    })(AppApiProvider as FC) as FC<{ children: React.ReactNode }>
  }, [])

  return <Provider>{children}</Provider>
}

function RootLayout({ children }: { children: React.ReactNode }) {
  return (
    // ensure the app has a default background color.
    <View flex={1} backgroundColor="$background">
      <UtmProvider>
        <StartupFlow>
          <AuthenticatedAppNavigation>{children}</AuthenticatedAppNavigation>
        </StartupFlow>
      </UtmProvider>
    </View>
  )
}

function AppNavigation({ children }: { children: React.ReactNode }) {
  useFetchThreadsIntoStore()
  return <TabNavigator>{children}</TabNavigator>
}

const AuthenticatedAppNavigation = withAuthenticationRequired(AppNavigation)
