import { Button, ShadowlessDialogContent } from "@my/ui"
import { Images, loadImageAsset } from "app/assets"
import { mixpanel, trackButtonClicked } from "app/telemetry"
import { formatUtmForMixpanel } from "app/utm/formatUtmForMixpanel"
import { useUtm } from "app/utm/useUtm"
import { useState } from "react"
import { Dialog, Text, View, XStack, YStack, useMedia, Image, H2 } from "tamagui"
export type FirstTimeUserExperienceProps = {
  onComplete: () => void
}

export const FirstTimeUserExperience: React.FC<FirstTimeUserExperienceProps> = ({ onComplete }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(true)
  const { sm, xs, md, short } = useMedia()
  const { utmParameters, isLoading: isUtmLoading } = useUtm()

  const SwipeablePage = ({ page }: { page: (typeof pages)[number] }) => {
    mixpanel.trackOnMount(
      { page_name: page.name, ...formatUtmForMixpanel(utmParameters) },
      isUtmLoading,
    )

    const FTUEText = () => {
      return (
        <YStack width={md ? "100%" : "65%"} justifyContent="center">
          <H2
            textAlign="left"
            paddingBottom="$sm"
            paddingTop={short ? "$xl" : "$base"}
            justifyContent="center"
            color="$text"
            data-testid={page.testId}
          >
            {page.header}
          </H2>
          <Text data-testid="ftue-body" color="$text">
            {page.body}
          </Text>
        </YStack>
      )
    }
    return (
      <>
        {!sm && !md && (
          <XStack justifyContent="space-between">
            <FTUEText />
            <Image source={page.image} width={300} height={300} resizeMode="contain" />
          </XStack>
        )}
        {md && (
          <YStack justifyContent="center" alignItems="center">
            <View>
              {short ?
                <></>
              : <Image source={page.image} width={300} height={300} resizeMode="contain" />}
            </View>
            <FTUEText />
          </YStack>
        )}
        {sm ||
          (xs && (
            <XStack justifyContent="space-between">
              <FTUEText />
            </XStack>
          ))}
      </>
    )
  }

  const onNext = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1)
    } else {
      setIsModalOpen(false)
      onComplete()
    }
  }

  const onPrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  return (
    <Dialog open={isModalOpen} aria-label="welcome">
      <Dialog.Portal>
        <Dialog.Overlay
          key="overlay"
          animation="slow"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
          backgroundColor="$backgroundOverlay"
        />
        <ShadowlessDialogContent
          width="90%"
          maxWidth={775}
          height="90%"
          maxHeight={sm ? 380 : 550}
          padding="$0"
        >
          <Dialog.Title />
          <Dialog.Description />
          <YStack overflow="hidden" borderRadius="$5" padding="$base" flex={1}>
            <Button
              variant="tertiary"
              width="$7"
              height="$2.5"
              alignSelf="flex-end"
              onPress={() => {
                onComplete()
                trackButtonClicked("Skip", pages[currentPage].name)
              }}
              data-testid="ftue-skip"
            >
              Skip
            </Button>

            <View justifyContent="center" marginLeft="$base">
              <SwipeablePage page={pages[currentPage]} />
            </View>

            <YStack alignItems="center" marginTop="auto">
              <XStack alignItems="center" alignContent="center" gap="$sm" justifyContent="center">
                {pages.map((_, i) => {
                  // TODO: Replace this with proper component for page tracking
                  if (currentPage === i) {
                    return (
                      <Button
                        unstyled
                        height={10}
                        width={10}
                        borderRadius={10}
                        key={i}
                        backgroundColor="$textAction"
                        onPress={() => setCurrentPage(i)}
                      />
                    )
                  } else {
                    return (
                      <Button
                        unstyled
                        height={10}
                        width={10}
                        borderRadius={10}
                        key={i}
                        backgroundColor="$textSecondary"
                        onPress={() => setCurrentPage(i)}
                      />
                    )
                  }
                })}
              </XStack>

              <XStack alignItems="center" alignContent="center" gap="$sm" marginTop="$sm">
                <Button width="$11" height="$2.5" onPress={onPrevious} disabled={currentPage === 0}>
                  Previous
                </Button>
                <Button
                  backgroundColor="$buttonPrimary"
                  color="$textDark"
                  width="$11"
                  height="$2.5"
                  hoverStyle={{
                    backgroundColor: "$buttonPrimaryHover",
                    borderColor: "$buttonPrimaryHover",
                  }}
                  onPress={() => {
                    onNext()
                    trackButtonClicked("Next", pages[currentPage].name)
                  }}
                  data-testid="ftue-next-button"
                >
                  Next
                </Button>
              </XStack>
            </YStack>
          </YStack>
        </ShadowlessDialogContent>
      </Dialog.Portal>
    </Dialog>
  )
}

const ftueImage1 = loadImageAsset(Images.FTUE1)
const ftueImage2 = loadImageAsset(Images.FTUE2)
const ftueImage3 = loadImageAsset(Images.FTUE3)

const pages = [
  {
    name: "FTUE:StartConversation",
    header: "Start with a conversation.",
    body: "Connect with your AI Health Coach 24/7 and receive personalized feedback when you need it most.",
    image: ftueImage1,
    testId: "ftue-start-convo",
  },
  {
    name: "FTUE:Insight",
    header: "Insight to help you manage your health.",
    body: "Healthy can detect health details or symptoms from your conversations, offering a truly personalized experience.",
    image: ftueImage2,
    testId: "ftue-insight",
  },
  {
    name: "FTUE:StayOnTrack",
    header: "Stay on track.",
    body: "Set up daily check-ins to monitor physical and mental health, symptoms, fitness, or any other needs.",
    image: ftueImage3,
    testId: "ftue-stay-on-track",
  },
] as const
