import { Platform } from "react-native"

export function generateShadow({
  width = 0,
  height = 2,
  opacity = 0.1,
  radius = 4,
}: {
  width?: number
  height?: number
  opacity?: number
  radius?: number
} = {}) {
  return Platform.select({
    ios: {
      shadowColor: "#000",
      shadowOffset: { width, height },
      shadowOpacity: opacity,
      shadowRadius: radius,
    },
    android: {
      elevation: Math.ceil(height / 2),
    },
    web: {
      boxShadow: `0px ${height}px ${Math.ceil(height * 2.5)}px rgba(0, 0, 0, ${opacity})`,
    },
  } as const)
}
