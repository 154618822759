import { RegEx } from "@my/utils/src/enums/regex"

export function reviveWithDates(key: string, value: any) {
  if (typeof value === "string") {
    const result = RegEx.isoDate.exec(value)
    if (result) {
      return new Date(value)
    }
  }
  return value
}
