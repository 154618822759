import { Thread, useHttpClient } from "@my/api"
import { useEffect } from "react"
import { useThreadStore } from "../state/threadState"

export function useFetchThreadsIntoStore(): Thread[] {
  const { threads, setThreads } = useThreadStore()
  const httpClient = useHttpClient()

  useEffect(() => {
    httpClient.get<{ threads: Thread[] }>("/threads").then((response) => {
      setThreads(response?.threads ?? [])
    })
  }, [httpClient])

  return threads
}
