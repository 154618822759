import { isWeb } from "tamagui"
import { createContext } from "react"
import { MMKV } from "react-native-mmkv"

export type StoredValue = boolean | string | number | Uint8Array
export type StoredValueType = "boolean" | "string" | "number" | "buffer"

const storage = new MMKV({
  id: "mmkv.default",
  encryptionKey: isWeb ? undefined : "C10seyC10sers@re@lwaysC10sing",
})

export const StorageContext = createContext<MMKV>(storage)
