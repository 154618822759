import { generateShadow } from "@my/utils/src/styleUtils/shadow"
import { Toast, useToastState } from "@tamagui/toast"
import { YStack } from "tamagui"
import { ReactNode } from "react"

export const NativeToast = () => {
  const currentToast = useToastState()

  if (!currentToast || currentToast.isHandledNatively) {
    return null
  }

  const customContent = currentToast.customData?.customContent as ReactNode

  return (
    <Toast
      key={currentToast.id}
      duration={currentToast.duration}
      viewportName={currentToast.viewportName}
      enterStyle={{ opacity: 0, scale: 0.5, y: -25 }}
      exitStyle={{ opacity: 0, scale: 1, y: -20 }}
      y={0}
      opacity={1}
      scale={1}
      animation="quick"
      backgroundColor="$backgroundSecondary"
      borderWidth="$0.5"
      borderColor="$borderContainer"
      borderRadius="$5"
      alignItems="center"
      {...generateShadow()}
    >
      <YStack paddingVertical="$xxs" paddingHorizontal="$2">
        {customContent ?? (
          <>
            <Toast.Title lineHeight="$1">{currentToast.title}</Toast.Title>
            {!!currentToast.message && (
              <Toast.Description>{currentToast.message}</Toast.Description>
            )}
          </>
        )}
      </YStack>
    </Toast>
  )
}
