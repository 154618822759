import { useMemo } from "react"
import { UtmContext } from "./UtmContext"
import { useCaptureUtm } from "./useCaptureUtm"

export const UtmProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const utmContext = useCaptureUtm()

  const contextValue = useMemo(
    () => ({
      ...utmContext,
    }),
    [utmContext],
  )

  return <UtmContext.Provider value={contextValue}>{children}</UtmContext.Provider>
}
