import { datadogLogs } from "@datadog/browser-logs"
import { Environment } from "@my/config/src/environment"
import { LogOptions } from "./logger"

datadogLogs.init({
  clientToken: Environment.DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "healthcoach-web",
  forwardErrorsToLogs: true,
})
if (Environment.ENV === "dev") {
  datadogLogs.logger.setHandler("console")
}

export const logger = {
  log(
    message: string,
    level: "debug" | "info" | "warn" | "error",
    context: object | undefined | LogOptions,
  ) {
    datadogLogs.logger.log(message, { ...context, level: level })
  },

  debug(message: string, context?: object | undefined | LogOptions) {
    this.log(message, "debug", context)
  },

  info(message: string, context?: object | undefined | LogOptions) {
    this.log(message, "info", context)
  },

  warn(message: string, context?: object | undefined | LogOptions) {
    this.log(message, "warn", context)
  },

  error(message: string, context?: object | undefined | LogOptions) {
    this.log(message, "error", context)
  },
}
