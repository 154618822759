import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react"
import { Environment } from "@my/config/src/environment"
import React, { useMemo } from "react"
import { AuthContext } from "./AuthContext"
import { useAuth } from "./useAuth"
import { Logger } from "./constants"

export const getAccessTokenWithBackoff = async (
  auth0: Auth0ContextInterface<User>,
  retries: number,
  delay: number,
  increment: number,
  logger?: Logger,
): Promise<string | undefined> => {
  const retryErrors = ["timeout", "unhandled_error"]
  let attempt = 0
  let token: string | undefined = undefined
  while (attempt < retries) {
    try {
      token = await auth0.getAccessTokenSilently()
      return token
    } catch (e) {
      if (retryErrors.indexOf(e.error) === -1) {
        const msg = `Authentication failed: ${e.error}`
        logger?.error(msg)
        return undefined
      }
      attempt++
      if (attempt < retries) {
        logger?.info(`Received Retrying authorization in ${delay}ms...`)
        await new Promise((resolve) => setTimeout(resolve, delay))
        delay += increment
      } else {
        logger?.error(`Unable to recover from Auth0 error ${e.error}`)
      }
    }
  }

  return token
}

export function AuthProvider({
  onLogout,
  logger,
  children,
}: {
  onLogin: () => void
  onLogout: () => Promise<void>
  logger: Logger
  children: React.ReactNode
}): JSX.Element {
  const auth0 = useAuth0()

  const context = useMemo<ReturnType<typeof useAuth>>(
    () =>
      ({
        isLoading: auth0.isLoading,
        isAuthenticated: auth0.isAuthenticated,
        error: auth0.error || null,
        user: auth0.user || null,
        getAccessToken: async () => {
          let token: string | undefined = undefined
          token = await getAccessTokenWithBackoff(auth0, 5, 1000, 1000, logger)
          if (!token) {
            await auth0.loginWithRedirect()
          }
          return token
        },
        login: () => auth0.loginWithRedirect(),
        signup: () =>
          auth0.loginWithRedirect({
            authorizationParams: {
              screen_hint: "signup",
              prompt: "login",
            },
          }),
        logout: async () => {
          await onLogout()
          await auth0.logout({ logoutParams: { returnTo: Environment.AUTH0_REDIRECT_URI } })
        },
      }) as const,
    [auth0, onLogout],
  )
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}
