export const QueryParams = {
  threadId: "threadId",
  displayName: "displayName",
  categoryName: "categoryName",
  subcategoryId: "subcategoryId",
  subcategoryName: "subcategoryName",
  dataType: "dataType",
  units: "units",
} as const

export const paramThreadIdNew = "new"
