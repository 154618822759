import { YStack, XStack, Separator, SizableText, YStackProps, Stack } from "tamagui"
import { trackButtonClicked } from "app/telemetry"
import { Link } from "solito/link"
import { FC } from "react"
import { Pressable } from "react-native"
import { useMedia } from "tamagui"

export const LegalLinks: FC<YStackProps> = (props) => {
  const { xs } = useMedia()
  return (
    <YStack alignContent="center" justifyContent="center" alignItems="center" {...props}>
      <SizableText color="$text" fontSize="$3" marginBottom="$xs">
        &copy;2024 ClosedLoop
      </SizableText>

      <Stack
        flexDirection={xs ? "column" : "row"}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        gap="$xs"
        flexWrap="wrap"
      >
        <XStack
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          gap="$xs"
          flexWrap="wrap"
        >
          <Pressable onPress={() => trackButtonClicked("TermsOfUse", "SettingsScreen")}>
            <Link href="https://www.closedloop.ai/hc-terms-of-use/" target="_blank">
              <SizableText fontSize="$3" color="$textLink">
                Terms of Use
              </SizableText>
            </Link>
          </Pressable>

          <Separator vertical background="$textSecondary" height={16} />

          <Pressable onPress={() => trackButtonClicked("PrivacyPolicy", "SettingsScreen")}>
            <Link href="https://www.closedloop.ai/consumer-privacy-policy/" target="_blank">
              <SizableText fontSize="$3" color="$textLink">
                Privacy Policy
              </SizableText>
            </Link>
          </Pressable>
        </XStack>

        {!xs && <Separator vertical background="$textSecondary" height={16} />}

        <XStack
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          gap="$xs"
          flexWrap="wrap"
        >
          <Pressable onPress={() => trackButtonClicked("MHMDActHealthPrivacy", "SettingsScreen")}>
            <Link
              href="https://about.gethealthy.com/mhmd-act-consumer-health-privacy/"
              target="_blank"
            >
              <SizableText fontSize="$3" color="$textLink">
                MHMD Act
              </SizableText>
            </Link>
          </Pressable>

          <Separator vertical background="$textSecondary" height={16} />

          <Pressable onPress={() => trackButtonClicked("QualitySafety", "SettingsScreen")}>
            <Link href="https://about.gethealthy.com/hc-quality-safety/" target="_blank">
              <SizableText fontSize="$3" color="$textLink">
                Quality &amp; Safety
              </SizableText>
            </Link>
          </Pressable>
        </XStack>
      </Stack>
    </YStack>
  )
}
