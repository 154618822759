export enum DayOfTheWeek {
  Sunday = "sunday",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
}

export type CheckInSchedule = {
  enabled: boolean
  checkInTime: string
  daysOfTheWeek: DayOfTheWeek[]
}
