import { createAnimations } from "@tamagui/animations-react-native"

export interface TimingObject {
  type: string
  duration: number
}

export interface TimingObjects {
  [key: string]: TimingObject
}

export interface TimingOptions {
  min?: number
  max?: number
  step?: number
}

/**
 * Generates a TimingObjects object containing timing objects for durations ranging from a specified minimum to a maximum value.
 *
 * @param {number} options.min - The minimum duration in milliseconds (default is 100).
 * @param {number} options.max - The maximum duration in milliseconds (default is 1000).
 * @param {number} options.step - The step value to increment the duration (default is 100).
 * @return {TimingObjects} An object containing timing objects for various durations.
 */
export function generateTimingObjects({
  min = 100,
  max = 1000,
  step = 100,
}: TimingOptions = {}): TimingObjects {
  const timings: TimingObjects = {}

  for (let i = min; i <= max; i += step) {
    timings[`${i}ms`] = {
      type: "timing",
      duration: i,
    }
  }

  return timings
}

export const animations = createAnimations({
  "100ms": {},
  "200ms": {},
  "300ms": {},
  "400ms": {},
  "500ms": {},
  "600ms": {},
  "700ms": {},
  "800ms": {},
  "900ms": {},
  "1000ms": {},
  ...generateTimingObjects(),
  bouncy: {
    damping: 9,
    mass: 0.9,
    stiffness: 150,
  },
  lazy: {
    damping: 18,
    stiffness: 50,
  },
  medium: {
    damping: 15,
    stiffness: 120,
    mass: 1,
  },
  slow: {
    damping: 15,
    stiffness: 40,
  },
  quick: {
    damping: 20,
    mass: 1.2,
    stiffness: 250,
  },
  tooltip: {
    damping: 10,
    mass: 0.9,
    stiffness: 100,
  },
})
