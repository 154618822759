import { useHttpClient } from "@my/api"
import { useUserStore } from "app/global-state/userState"
import { useEffect } from "react"

export function TimezoneListener({ children }: { children: React.ReactNode }) {
  const httpClient = useHttpClient()
  const { user, setUser } = useUserStore()

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (user && timeZone != user.timeZone) {
      const updatedUser = { ...user, timeZone }
      setUser(updatedUser)

      httpClient.patch(`/users/${user.id}`, updatedUser).then((result) => {
        if (result) {
          console.debug("User's timezone updated")
        }
      })
    }
  }, [user?.auth0Id, setUser, httpClient])

  return <>{children}</>
}
