import { useLDClient } from "launchdarkly-react-client-sdk"
import { useMemo } from "react"
import { FeatureFlagContext } from "./FeatureFlagContext"

export function FeatureFlagProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const featureClient = useLDClient()
  const context = useMemo(
    () =>
      ({
        identify: (email: string) => featureClient?.identify({ kind: "user", key: email, email }),
      }) as const,
    [featureClient],
  )
  return <FeatureFlagContext.Provider value={context}>{children}</FeatureFlagContext.Provider>
}
