import { isJest } from "@my/config/src/constants"
import { Environment } from "@my/config/src/environment"
import { Mixpanel, MixpanelEvent, MixpanelProperties } from "app/types/mixpanel"
import { Config, default as sdkMixpanel } from "mixpanel-browser"
import { useEffect, useRef } from "react"

const ENABLED = !isJest() && Environment.MIXPANEL_TOKEN !== "disabled"
const mp = ENABLED ? sdkMixpanel : undefined
console.debug("Mixpanel enabled", ENABLED)

export const mixpanel: Mixpanel = {
  init: () => {
    const config: Partial<Config> = {
      ignore_dnt: true,
      ip: false,
    }
    mp?.init(Environment.MIXPANEL_TOKEN, config)
  },
  /**
   * Gets the distinct ID for the current user from Mixpanel
   * @returns Promise that resolves to the distinct ID string if Mixpanel is enabled, undefined otherwise
   */
  getDistinctId: () => {
    return Promise.resolve(mp?.get_distinct_id())
  },
  /**
   * Creates an alias in Mixpanel to merge two distinct IDs
   * @param alias - The anonymous ID to use as the alias (typically the deviceId)
   * @param distinctId - The authenticated ID to alias (typically the auth0 ID)
   */
  alias: (alias: string, distinctId: string) => {
    if (!alias || !distinctId) {
      return
    }
    mp?.alias(alias, distinctId)
  },
  identify: (authId: string): Promise<void> | undefined => {
    return mp?.identify(authId)
  },
  setProfile: (properties) => {
    mp?.people.set(properties)
  },
  setSuperProperties: (properties) => {
    mp?.register(properties)
  },
  clearMixpanelData: () => {
    mp?.reset()
  },
  track: (eventName: MixpanelEvent, properties: MixpanelProperties) => {
    mp?.track(eventName, properties)
  },
  trackOnMount: (properties: MixpanelProperties, isLoading = false) => {
    const hasTracked = useRef(false)

    useEffect(() => {
      if (hasTracked.current) return

      if (!isLoading) {
        mp?.track("Page Viewed", properties)
        hasTracked.current = true
      }
    }, [isLoading])
  },
}
