import defaultColors from "../default/tokens"

export const light = {
  // Tamagui built-in theme values
  background: "#FBF9F7",
  backgroundPrimary: "#eaf1fb",
  backgroundHover: "#F2FOEE",
  backgroundPress: "#F2FOEE",
  backgroundFocus: "#FFFFFF",

  borderColor: "#E2E2E2",
  borderColorHover: "#ABABAB",
  borderColorPress: "#919191",
  borderColorFocus: "#FABDOO",

  color: defaultColors.light.slate12,
  colorInverse: defaultColors.light.slate1,
  colorHover: defaultColors.light.slate12,
  colorPress: defaultColors.light.slate12,
  colorFocus: defaultColors.light.slate12,

  shadowColor: "#919191",
  shadowColorHover: "#919191",
  shadowColorPress: "#919191",
  shadowColorFocus: "#919191",

  // ClosedLoop additional theme values
  backgroundSecondary: "#F1F1F1",
  backgroundDetail: "#f6f8fc",
  backgroundSelected: "#D1E4FF",
  backgroundInfo: "#5394D9",
  backgroundSuccess: "#02A665",
  backgroundSuccessLight: "#1D6D1B",
  backgroundWarning: "#FA9200",
  backgroundWarningLight: "#FFDCBF",
  backgroundError: "#D44343",
  backgroundInverse: "#1B1C1B",
  backgroundOverlay: "rgba(0, 0, 0, 0.48)",
  backgroundInput: "#f6f8fc",
  backgroundMessage: "#f6f8fc",

  borderContainer: "#E2E2E2",
  borderError: "#D44343",
  borderWarning: "#ED5F00",
  borderFocus: "#4C5155",
  borderSelected: "#0091FF",

  buttonPrimary: "#306C75",
  buttonPrimaryHover: "#609AA3",
  buttonPrimaryPress: "#2A676F",
  buttonPrimaryFocus: "#609AA3",
  buttonPrimaryDisabled: "#C6C6C6",
  buttonPrimaryText: "#F9F9F9",
  buttonSecondary: "#D0E7E6",
  buttonSecondaryHover: "#E7FEFD",
  buttonSecondaryPress: "#B4CBCA",
  buttonSecondaryFocus: "#E7FEFD",
  buttonSecondaryDisabled: defaultColors.light.slate8,
  buttonSecondaryText: "#241A09",
  buttonTertiary: "transparent",
  buttonTertiaryHover: "#E0ECFF",
  buttonTertiaryPress: "#6FAFF5",
  buttonTertiaryFocus: "#9ECAFF",
  buttonTertiaryDisabled: "transparent",
  buttonTertiaryText: "#241A09",

  icon: "#1B1C1B",
  iconDark: "#F9F9F9",
  iconLight: "#1B1C1B",
  iconSecondary: "#5E5E5E",
  iconError: "#D44343",
  iconWarning: "#ED5F00",
  iconSuccess: "#ECFFE2",
  iconInfo: "#0961A2",
  iconSelected: "#347ABD",
  iconAction: "#347ABD",
  iconDangerous: "#D44343",
  iconDisabled: "#C7C6C4",
  iconBrand: "#306C75",

  text: "#1B1C1B",
  textInverse: "#1B1C1B",
  textHover: defaultColors.light.slate12,
  textPress: defaultColors.light.slate12,
  textFocus: defaultColors.light.slate12,
  textSecondary: "#5E5E5E",
  textDisabled: "#C7C6C4",
  textDark: "#F9F9F9",
  textLight: "#1B1C1B",
  textLink: "#347ABD",
  textAction: "#347ABD",
  textSelected: defaultColors.light.slate12,
  textDangerous: "#D44343",
  textWarning: "#D47B00",

  chartAxis: "#E2E2E2",
  chartGrid: "#919191",
  chartGridDark: "#777777",
  chartLabel: "#919191",
  chartTicks: "#919191",
  chartTicksLabels: "#919191",
  chartDataBorder: "#E2E2E2",
  chartDataPrimary: "#9ECAFF",
  chartDataWarning: "#FA920E",

  switchOff: "#919191",
  switchOn: "#02A665",
  switchThumb: "#FBF9F7",
}

export type BaseTheme = typeof light

export const dark: BaseTheme = {
  // Tamagui built-in theme values
  background: "#1B1B1B",
  backgroundPrimary: "#373737",
  backgroundHover: "#1B1B1B",
  backgroundPress: "#1B1B1B",
  backgroundFocus: "#FFFFFF",

  borderColor: "#474747",
  borderColorHover: "#303030",
  borderColorPress: "#303030",
  borderColorFocus: "#303030",

  color: defaultColors.dark.slate12,
  colorInverse: defaultColors.dark.slate1,
  colorHover: defaultColors.dark.slate11,
  colorPress: defaultColors.dark.slate11,
  colorFocus: defaultColors.dark.slate11,

  shadowColor: "#1B1B1B",
  shadowColorHover: "#1B1B1B",
  shadowColorPress: "#1B1B1B",
  shadowColorFocus: "#1B1B1B",

  // ClosedLoop additional theme values
  backgroundSecondary: "#303030",
  backgroundDetail: "#212121",
  backgroundSelected: "#003258",
  backgroundInfo: "#5394D9",
  backgroundSuccess: "#02A665",
  backgroundSuccessLight: "#1D6D1B",
  backgroundWarning: "#FA9200",
  backgroundWarningLight: "#FFDCBF",
  backgroundError: "#D44343",
  backgroundInverse: "#1B1C1B",
  backgroundOverlay: "rgba(0, 0, 0, 0.48)",
  backgroundInput: "#474747",
  backgroundMessage: "#111111",

  borderContainer: "#474747",
  borderError: "#F75C59",
  borderWarning: "#FF802B",
  borderFocus: "#4C5155",
  borderSelected: "#0091FF",

  buttonPrimary: "#306C75",
  buttonPrimaryHover: "#609AA3",
  buttonPrimaryPress: "#2A676F",
  buttonPrimaryFocus: "#609AA3",
  buttonPrimaryDisabled: "#C6C6C6",
  buttonPrimaryText: "#EBEBEB",
  buttonSecondary: "#D0E7E6",
  buttonSecondaryHover: "#E7FEFD",
  buttonSecondaryPress: "#B4CBCA",
  buttonSecondaryFocus: "#E7FEFD",
  buttonSecondaryDisabled: defaultColors.light.slate8,
  buttonSecondaryText: "#241A09",
  buttonTertiary: "transparent",
  buttonTertiaryHover: "#E0ECFF",
  buttonTertiaryPress: "#6FAFF5",
  buttonTertiaryFocus: "#9ECAFF",
  buttonTertiaryDisabled: "transparent",
  buttonTertiaryText: "#EBEBEB",

  icon: "#EBEBEB",
  iconDark: "#1B1B1B",
  iconLight: "#EBEBEB",
  iconSecondary: "#919191",
  iconError: "#F75C59",
  iconWarning: "#FF802B",
  iconSuccess: "#ECFFE2",
  iconInfo: "#5394D9",
  iconSelected: "#5394D9",
  iconAction: "#5394D9",
  iconDangerous: "#F75C59",
  iconDisabled: "#5E5E5E",
  iconBrand: "#306C75",

  text: "#EBEBEB",
  textInverse: "#EBEBEB",
  textHover: defaultColors.dark.slate11,
  textPress: defaultColors.dark.slate11,
  textFocus: defaultColors.dark.slate11,
  textSecondary: "#919191",
  textDisabled: "#5E5E5E",
  textDark: "#1B1B1B",
  textLight: "#EBEBEB",
  textLink: "#5394D9",
  textAction: "#5394D9",
  textSelected: defaultColors.dark.slate12,
  textDangerous: "#F75C59",
  textWarning: "#D47B00",

  chartAxis: "#303030",
  chartGrid: "#777777",
  chartGridDark: "#ABABAB",
  chartLabel: "#919191",
  chartTicks: "#777777",
  chartTicksLabels: "#919191",
  chartDataBorder: "#303030",
  chartDataPrimary: "#9ECAFF",
  chartDataWarning: "#FF802B",

  switchOff: defaultColors.dark.slate9,
  switchOn: "#02A665",
  switchThumb: "#474747",
}

export default {
  light,
  dark,
}
