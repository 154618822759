import { useContext } from "react"
import { UtmContext } from "./UtmContext"

export const useUtm = () => {
  const context = useContext(UtmContext)
  if (!context) {
    throw new Error("useUtmContext must be used within a UtmProvider")
  }
  return context
}
