import { GetThemeValueForKey, useTheme } from "tamagui"

export enum IconType {
  Regular = "far",
  Solid = "fas",
}

export type ColorType = string | GetThemeValueForKey<"color">

export function translateColor(color: ColorType, theme: ReturnType<typeof useTheme>): string {
  if (typeof color === "string" && color.startsWith("$")) {
    return theme[color.slice(1)]?.val
  }
  return color as string
}
