import { getTokenValue, Token, Tokens } from "tamagui"
import { PixelRatio, Keyboard, EmitterSubscription, Platform } from "react-native"
import { useCallback, useEffect } from "react"

/**
 * Converts a Tamagui token or numeric value to its corresponding numeric value.
 *
 * This function is used to resolve Tamagui tokens or return numeric values as-is.
 * It's particularly useful when working with components that require numeric values
 * but you want to use Tamagui's token system for consistency.
 *
 * @param {Token | 'unset' | 'auto' | number} size - The size to convert. Can be a Tamagui token, 'unset', 'auto', or a numeric value.
 * @param {keyof Tokens} [tokenType] - Optional. The type of token to retrieve (e.g., 'size', 'space', 'radius', etc.).
 * @returns {number} The numeric value corresponding to the input size.
 *
 * @example
 * const numericSize1 = getTokenSizeAsNumber('$4', 'size');
 * const numericSize2 = getTokenSizeAsNumber(100);
 * // numericSize1 will be the numeric value of the '$4' size token
 * // numericSize2 will be 100
 *
 * @remarks
 * - This function uses the getTokenValue function from Tamagui to resolve tokens.
 * - If a numeric value is provided, it is returned as-is.
 * - This is useful for components that don't support Tamagui tokens directly but you want to use the token system for consistency.
 * - Note that this function does not perform any density-independent pixel conversion.
 */
export const getTokenSizeAsNumber = (
  size: Token | "unset" | "auto" | number,
  tokenType?: keyof Tokens,
): number => {
  return typeof size === "string" ? getTokenValue(size, tokenType) : size
}

/**
 * Converts a pixel value to its density-independent equivalent.
 *
 * This function takes a pixel value and multiplies it by the current pixel density
 * of the device. This is useful for creating consistent layouts across devices
 * with different pixel densities.
 *
 * @param {number} pixels - The pixel value to convert.
 * @returns {number} The density-independent pixel value.
 *
 * @example
 * const densityIndependentValue = getDensityIndependentPixels(16);
 * // If the pixel ratio is 2, densityIndependentValue will be 32
 *
 * @remarks
 * - This function uses React Native's PixelRatio API to get the current pixel density.
 * - The returned value is rounded to the nearest whole number to avoid sub-pixel rendering issues.
 * - This is particularly useful for creating responsive layouts that look consistent across different devices.
 */
export const getDensityIndependentPixels = (pixels: number | undefined): number => {
  if (pixels === undefined) return 0
  return Math.round(PixelRatio.getPixelSizeForLayoutSize(pixels))
}

/**
 * Hook to automatically dismiss keyboard on specific events.
 * Handles platform-specific keyboard behavior for iOS and Android.
 *
 * @returns {() => void} Function to manually dismiss keyboard
 *
 * @example
 * const dismissKeyboard = useKeyboardDismiss()
 */
export const useKeyboardDismiss = (): (() => void) => {
  const dismissKeyboard = useCallback(() => {
    Keyboard.dismiss()
  }, [])

  useEffect(() => {
    const listeners = new Set<EmitterSubscription>()

    // Initial dismiss
    dismissKeyboard()

    // Add show listener for both platforms
    listeners.add(Keyboard.addListener("keyboardDidShow", dismissKeyboard))

    if (Platform.OS === "ios") {
      // iOS-specific hide listener
      listeners.add(
        Keyboard.addListener("keyboardDidHide", () => {
          const timeoutId = setTimeout(dismissKeyboard, 100)
          return () => clearTimeout(timeoutId)
        }),
      )
    } else if (Platform.OS === "android") {
      // Android-specific listeners
      listeners.add(
        Keyboard.addListener("keyboardDidHide", () => {
          // Android sometimes needs an immediate dismiss
          dismissKeyboard()
        }),
      )

      // Handle Android soft keyboard showing
      listeners.add(
        Keyboard.addListener("keyboardDidChangeFrame", ({ endCoordinates }) => {
          if (endCoordinates.height > 0) {
            dismissKeyboard()
          }
        }),
      )
    }

    return () => {
      listeners.forEach((listener) => listener.remove())
    }
  }, [dismissKeyboard])

  return dismissKeyboard
}
