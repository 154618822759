export type EventName = string

export interface EventSubscription {
  remove: () => void
}
class _EventSubscription implements EventSubscription {
  eventBus: _EventBus
  event: EventName
  key: number

  constructor(eventBus: _EventBus, event: EventName, key: number) {
    this.eventBus = eventBus
    this.event = event
    this.key = key
  }

  remove() {
    this.eventBus.removeListener(this)
  }
}

type Listeners = Map<number, (context: any) => void | Promise<void>>

class _EventBus {
  private listeners = new Map<EventName, Listeners>()
  private key = 1

  addListener(
    event: EventName,
    listener: (context: any) => void | Promise<void>,
  ): EventSubscription {
    const listeners = this.listeners.get(event) ?? new Map()
    const key = this.key++
    listeners.set(key, listener)
    this.listeners.set(event, listeners)
    return new _EventSubscription(this, event, key)
  }

  removeListener(subscription: EventSubscription) {
    const mySubscription = subscription as _EventSubscription
    const listeners = this.listeners.get(mySubscription.event)
    listeners?.delete(mySubscription.key)
  }

  emit(event: EventName, context?: any) {
    const listeners = this.listeners.get(event)
    listeners?.forEach((listener) => listener(context))
  }

  async emitSync(event: EventName, context?: any) {
    const listeners = this.listeners.get(event)
    if (listeners) {
      for (const listener of listeners.values()) {
        await listener(context)
      }
    }
  }
}

export const EventBus = new _EventBus()
