import { UserUtmParameters } from "@my/api"

/**
 * Parses a URL-formatted string into UTM parameters
 * @param referrer - URL-formatted string containing UTM parameters
 * @returns UserUtmParameters object or undefined if parsing fails
 */
export const parseUtmParameters = (referrer: string): UserUtmParameters | undefined => {
  const params = new URLSearchParams(referrer)
  const utmParams = {
    utmSource: params.get("utm_source") ?? undefined,
    utmMedium: params.get("utm_medium") ?? undefined,
    utmCampaign: params.get("utm_campaign") ?? undefined,
    utmTerm: params.get("utm_term") ?? undefined,
    utmContent: params.get("utm_content") ?? undefined,
  }

  // Only return UTM params if at least one parameter has a value
  return Object.values(utmParams).some((value) => value !== undefined) ? utmParams : undefined
}
