import { createInterFont } from "@tamagui/font-inter"

/**
 * Shared font settings.
 *
 * Note: The tokens with comments are the default tokens for those elements via Tamagui.
 */
const sharedFontSettings = {
  color: {
    1: "$color",
    2: "$color",
    3: "$color",
    4: "$color", // ListItem.Text, Label, Paragraph
    5: "$color", // H6 & Text
    6: "$color", // H5
    7: "$color", // H4
    8: "$color", // H3
    9: "$color", // H2
    10: "$color", // H1
    11: "$color",
  },
  // General reset of letter spacing
  letterSpacing: {
    1: 0,
    2: 0,
    3: 0,
    4: 0, // ListItem.Text, Label, Paragraph
    5: 0, // H6 & Text
    6: 0, // H5
    7: 0, // H4
    8: 0, // H3
    9: 0, // H2
    10: 0, // H1
    11: 0,
    12: 0,
    13: 0,
    14: 0,
    15: 0,
  },
  // Size in pixels
  size: {
    1: 13,
    2: 13,
    3: 13,
    4: 13, // ListItem.Text, Label, Paragraph
    5: 16, // H6 & Text
    6: 16, // H5
    7: 18, // H4
    8: 20, // H3
    9: 24, // H2
    10: 39, // H1
    11: 55,
  },
  weight: {
    1: "600",
    2: "600",
    3: "600",
    4: "500", // ListItem.Text, Label, Paragraph
    5: "500", // H6 & Text
    6: "600", // H5
    7: "500", // H4
    8: "500", // H3
    9: "400", // H2
    10: "400", // H1
    11: "400",

    100: "100",
    thin: "100",
    200: "200",
    extraLight: "200",
    300: "300",
    light: "300",
    400: "400",
    normal: "400",
    500: "500",
    medium: "500",
    600: "600",
    semibold: "600",
    700: "700",
    bold: "700",
    800: "800",
    extraBold: "800",
    900: "900",
    black: "900",
    950: "950",
    extraBlack: "950",
    ultraBlack: "950",
  },

  // for native
  face: {
    100: { normal: "InterThin" },
    300: { normal: "InterLight" },
    400: { normal: "InterRegular" },
    500: { normal: "InterMedium" },
    600: { normal: "InterSemiBold" },
    700: { normal: "InterBold" },
    800: { normal: "InterExtraBold" },
    900: { normal: "InterBlack" },
  },
}

export const headingFont = createInterFont(
  {
    ...sharedFontSettings,
    size: {
      5: 14, // H6
    },
    weight: {
      ...sharedFontSettings.weight,
      5: "700", // H6
    },
  },
  {
    // Round font size, no fractional px on web.
    sizeSize: (size) => Math.round(size),
    // responsive line height aligning with font size
    sizeLineHeight: (size) => Math.round(size) * 1.2,
  },
)

export const bodyFont = createInterFont(
  {
    ...sharedFontSettings,
    size: {
      6: 18, // Body Emphasized
    },
  },
  {
    // Round font size, no fractional px on web.
    sizeSize: (size) => Math.round(size),
    // responsive line height aligning with font size
    sizeLineHeight: (size) => Math.round(size) * 1.5,
  },
)
