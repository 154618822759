import { H1, Text, useTheme, YStack } from "tamagui"
import { Button } from "@my/ui"
import { UnknownErrorSvg } from "../../assets/images/UnknownErrorSvg"
import { useRouter } from "solito/router"
import { Routes } from "../../routing"

export function ErrorBoundaryFallback() {
  const router = useRouter()
  const theme = useTheme()

  return (
    <YStack justifyContent="center" alignItems="center" flex={1}>
      <H1 textAlign="center">Uh oh! An unknown error has occurred.</H1>
      <Text textAlign="center">Apologies, we’ve experienced an unknown error.</Text>
      <YStack>
        <UnknownErrorSvg accent={theme.iconInfo?.val} fill={theme.text?.val} />
        <Button
          variant="primary"
          size="$3"
          onPress={() => {
            router.replace(Routes.chat)
          }}
        >
          Take Me Back
        </Button>
      </YStack>
    </YStack>
  )
}
